import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Container from 'components/Container'

const CovidResponse = () => (
  <Layout>
    <Helmet title="Oregon City Smiles - Our response to COVID" />
    <Container>
      <h1 className="text-3xl text-center">Our response to COVID-19</h1>
      <div className="my-16">
        <h5 className="text-2xl mb-8">Dear Our Patient Family,</h5>

        <p className="text-base leading-snug my-4">
          We hope this letter finds you and your family in good health. Our
          community has been through a lot over the last couple months, and all
          of us are looking forward to resuming our normal habits and routines.
          While many things have changed, one thing has remained the same: our
          commitment to your safety.
        </p>
        <p className="text-base leading-snug my-4">
          Infection control has always been a top priority for our practice and
          you may have seen this during your visits to our office. We will be
          opening our doors again on May 4th, Monday. Our infection control
          processes are made so that when you receive care, it’s both safe and
          comfortable. We want to tell you about the infection control
          procedures we follow in our practice to keep patients and staff safe.
        </p>
        <p className="text-base leading-snug my-4">
          Our office follows infection control recommendations made by the
          American Dental Association (ADA), the U.S. Centers for Disease
          Control and Prevention (CDC) and the Occupational Safety and Health
          Administration (OSHA). We follow the activities of these agencies so
          that we are up-to-date on any new rulings or guidance that may be
          issued. We do this to make sure that our infection control procedures
          are current and adhere to each agencies’ recommendations.
        </p>
        <p className="text-base leading-snug my-4">
          You may see some changes when it is time for your next appointment. We
          made these changes to help protect our patients and staff. For
          example:
        </p>
        <ul className="list-inside list-disc text-base ml-4">
          <li className="text-base my-2">
            Our office will communicate with you beforehand to ask some
            screening questions. You’ll be asked those same questions again when
            you are in the office.
          </li>
          <li className="text-base my-2">
            We have hand sanitizer that we will ask you to use when you enter
            the office. You will also find some in the reception area and other
            places in the office for you to use as needed.
          </li>
          <li className="text-base my-2">
            You may see that our waiting room will no longer offer magazines,
            children’s toys and so forth, since those items are difficult to
            clean and disinfect.
          </li>
          <li className="text-base my-2">
            Appointments will be managed to allow for social distancing between
            patients. That might mean that you’re offered fewer options for
            scheduling your appointment.
          </li>
          <li className="text-base my-2">
            We will do our best to allow greater time between patients to reduce
            waiting times for you, as well as to reduce the number of patients
            in the reception area at any one time.
          </li>
        </ul>

        <p className="text-base leading-snug my-4">
          We look forward to seeing you again and are happy to answer any
          questions you may have about the steps we take to keep you, and every
          patient, safe in our practice. To make an appointment, please call our
          office at office number or visit our website at web address.
        </p>
        <p className="text-base leading-snug my-4">
          Thank you for being our patient. We value your trust and loyalty and
          look forward to welcoming back our patients, neighbors and friends.
        </p>

        <p className="text-base leading-snug my-10">
          <span className="block mb-4">Sincerely,</span>
          Dental Family at Oregon City Smiles
        </p>
      </div>
    </Container>
  </Layout>
)

export default CovidResponse
